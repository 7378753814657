<template>
  <div class="Statistics">
    <div class="crumbs" style="position: relative;text-align: right">
      <a-icon @click="_returnPage" class="return" type="left" />
      <a-button type="primary" @click="_deriveData">导出数据</a-button>
    </div>
    <div class="container flex">
      <div class="content-right">
        <div v-if="type == 1">
          <div class="search-header flex" style="margin-bottom: 20px;">
            <div>
              <span class="search-text">查询时间</span>
              <a-range-picker
                style="flex: 1"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                @change="_createdDate"
              >
                <a-icon type="caret-down" slot="suffixIcon" />
              </a-range-picker>
              <span
                class="search-text"
                style="margin-left: 20px;"
                v-if="info.Relevance && info.Relevance.length != 0"
                >关联对象</span
              >
              <a-select
                style="width: 220px"
                v-if="info.Relevance && info.Relevance.length != 0"
                @change="_releanceObject"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in info.Relevance"
                  >{{ item.title }}</a-select-option
                >
              </a-select>
              <span style="margin-left: 10px;">
                <a-button type="primary" @click="_searchData">查询</a-button>
              </span>
            </div>

            <div class="sub-user">
              <img
                :src="item.Avatar"
                v-for="(item, index) in AnnserUserAvatarList"
                v-if="index < 11"
                alt=""
                @click="_personageData(item.UserId)"
              />
              <a-icon
                type="right"
                @click="_showCutData()"
                v-if="AnnserUserAvatarList.length > 10"
                class="more-user"
              />
            </div>
          </div>

          <div class="right-top">
            <div class="header">
              {{ $route.query.type == 2 ? '试卷标题：' : '问卷标题：'
              }}{{ info.Title }}
            </div>
            <div class="content">
              <div class="flex">
                <div class="row">所属分类：{{ info.typeName }}</div>
                <div class="row">创建人：{{ info.CreateUserName }}</div>
              </div>
              <div class="flex">
                <div class="row">当前问卷已收集：{{ info.RealCount }}</div>
                <div class="row">最后更新时间：{{ info.LastTime }}</div>
              </div>
              <div class="flex">
                <div class="row">问卷说明：{{ info.Explain }}</div>
              </div>
            </div>
          </div>
          <div class="content-list">
            <div class="list-item flex" v-for="(item, index) in optionList">
              <div class="title flex">
                <div
                  style="padding:30px;flex:1;border-right: 1px solid rgb(221, 221, 221);margin-right: -1px;"
                >
                  <div class="title-text">{{ index + 1 }}.{{ item.Title }}</div>
                  <div class="item-row" v-for="(item0, i) in item.Children">
                    {{ i + 1 }}.{{ item0.OptionTitle }}
                  </div>
                </div>
                <div
                  style="height: 100%;border-left:1px solid #ddd;padding:20px 0"
                >
                  <div class="data" v-if="item.Children.length > 0">
                    <div v-if="item.Chart.data.length > 0">
                      <div
                        :id="'main' + index"
                        style="margin: 0 auto;height:260px;"
                      ></div>
                      <div class="sub-num flex">
                        <span>答题人数：{{ item.AnnserUserCount }}</span>
                        <span
                          class="ant-menu-item-selected"
                          style="cursor: pointer;"
                          @click="_more(item.Chart.OptionId)"
                          >查看更多</span
                        >
                      </div>
                    </div>
                    <div
                      v-else
                      style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);font-size: 18px;color: #aaa"
                    >
                      暂无数据
                    </div>
                  </div>
                  <div class="data" v-if="item.Type == 4">
                    <div class="data-list" v-if="item.Chart.data.length > 0">
                      <div
                        class="list-row flex"
                        v-for="(item1, index) in item.Chart.data"
                      >
                        <span class="row-label">{{ item1.Answer }}</span>
                      </div>
                      <div
                        v-if="item.Chart.data.length == 0"
                        style="padding: .1rem;text-align: center;color:#aaa;margin: 10px 0"
                      >
                        暂无数据
                      </div>
                      <div class="list-row flex">
                        <span class="row-label"
                          >答题人数：{{ item.AnnserUserCount }}</span
                        >
                        <span
                          class="ant-menu-item-selected"
                          style="cursor: pointer;"
                          @click="_more(item.Chart.OptionId)"
                          >查看更多</span
                        >
                      </div>
                    </div>
                    <div
                      v-else
                      style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);font-size: 18px;color: #aaa"
                    >
                      暂无数据
                    </div>
                  </div>

                  <!--<div class="data" v-if="!item.Children.length==0">-->
                  <!--<div class="data-list">-->
                  <!--<div class="list-row flex">-->
                  <!--<span class="row-label" style="color: #999">{{item.Title}}答案</span>-->
                  <!--<span class="more" @click="_more(item.Chart.OptionId)">查看更多>></span>-->
                  <!--</div>-->
                  <!--<div class="list-row flex" v-for="(item1,index) in item.Chart.selectAnnswer">-->
                  <!--<span class="row-label">{{item1.Answer}}</span>-->
                  <!--</div>-->
                  <!--<div v-if="item.Chart.data.length==0" style="padding: .1rem;text-align: center;color:#aaa;margin: 10px 0">暂无数据</div>-->
                  <!--<div class="list-row flex">-->
                  <!--<span class="row-label">答题人数：{{item.AnnserUserCount}}</span>-->
                  <!--<span class="row-label" :OptionId="item.Chart.OptionId" @click="jumpGet(item.Chart.OptionId,index)">{{currPage}}/{{item.AnnserCount}} <a-input style="width:40px;" size="small" v-model="jumpPage"/> 跳转</span>-->
                  <!--</div>-->
                  <!--</div>-->
                  <!--</div>-->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="type == 2">
          <div class="right-top">
            <div class="header">
              {{ $route.query.type == 2 ? '试卷标题：' : '问卷标题：'
              }}{{ info.Title }}
            </div>
            <div class="content">
              <div class="flex">
                <div class="row">所属分类：{{ info.typeName }}</div>
                <div class="row">总分：{{ info.Score }}分</div>
              </div>
              <div class="flex">
                <div class="row">
                  及格分：{{ info.PassScore }}分(及格率{{ info.passrate }})
                </div>
                <div class="row">
                  答题时长：{{ info.Duration }}分钟(平均答题时长{{
                    info.averagetime
                  }})
                </div>
              </div>
              <div class="flex">
                <div class="row">
                  考试时间：{{ info.StartTime }}~{{ info.EndTime }}
                </div>
                <div class="row">创建人：{{ info.CreateUserName }}</div>
              </div>
              <div class="flex">
                <div class="row">考试说明：{{ info.Explain }}</div>
              </div>
            </div>
          </div>

          <div class="container-content">
            <a-table
              :columns="columns"
              :dataSource="tableData"
              :pagination="false"
              bordered
            ></a-table>
            <div style="margin: 15px;color: #666;">分段统计</div>
            <div>
              <div id="category" style="height: 450px"></div>
            </div>

            <div style="margin: 15px;color: #666;">考生信息</div>

            <div class="search-header" style="margin-bottom: 20px;">
              <span class="search-text">筛选考生</span>
              <a-select
                defaultValue="全部"
                style="width: 220px;margin-right: 30px;"
                @change="_screenStatus"
              >
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">及格</a-select-option>
                <a-select-option value="2">不及格</a-select-option>
                <a-select-option value="3">已考</a-select-option>
                <a-select-option value="4">漏考</a-select-option>
              </a-select>
              <span class="search-text">查询时间</span>
              <a-range-picker
                style="flex: 1"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                @change="_createdDate"
              >
                <a-icon type="caret-down" slot="suffixIcon" />
              </a-range-picker>

              <span class="search-text">区域</span>
              <div style="display:inline-block;position: relative">
                <person
                  class="person"
                  v-bind="personObj"
                  @getData="_relevanceObject"
                ></person>
                <a-input v-model="relevanceText" placeholder="区域"></a-input>
              </div>

              <a-button style="margin-left: 10px;" @click="_reset"
                >重置</a-button
              >

              <span style="margin-left: 10px;">
                <a-button type="primary" @click="_searchTableInfo(1)"
                  >查询</a-button
                >
              </span>

              <span style="margin-left: 100px;">
                <!-- <a-button type="primary" @click="sendResit()">发送补考试卷</a-button> -->

                <a-button type="primary" @click="sendResitModel">
                  发送补考试卷
                </a-button>
                <!-- <a-modal v-model="showSendResitVisible" title="发送补考试卷" @ok="SendResitOk">
                                  <p>系统会重新生成试卷发送给缺考和不及格的员工，确认发送吗？</p>
                                  标题：<a-input v-model="send_title" placeholder="标题"></a-input>
                                  副标题：<a-input v-model="send_subtitle" placeholder="副标题"></a-input>
                                </a-modal> -->
              </span>
            </div>
            <a-table
              :columns="examineeColumns"
              :dataSource="examineeData"
              :pagination="false"
              bordered
            ></a-table>
            <div style="margin: 20px 0;" v-if="WJPage > 10">
              <a-pagination
                showQuickJumper
                :defaultCurrent="1"
                :total="WJPage"
                @change="_PageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-drawer
      title="更多数据"
      placement="right"
      width="600"
      :closable="false"
      @close="onClose"
      :visible="visible"
    >
      <div class="MoreData">
        <ul class="issue-list">
          <li>问卷：{{ moreData.qaTitle }}</li>
          <li>问题：{{ moreData.OptionTitle }}</li>
        </ul>
        <a-table
          :columns="moreColumns"
          :dataSource="moreTableData"
          :pagination="false"
          bordered
        ></a-table>
        <div style="margin-top: 20px;">
          <a-pagination :total="moreTotal" @change="_moreTable" />
        </div>
      </div>
    </a-drawer>

    <a-drawer
      title="更多人员信息"
      width="480"
      :closable="false"
      @close="_cutData"
      :visible="cutData"
    >
      <a-select mode="multiple" style="width: 300px;" @change="_selectData">
        <a-select-option value=""
          >全部({{ AnnserUserAvatarList.length + '人' }})</a-select-option
        >
        <a-select-option
          :value="item.title"
          v-for="(item, index) in AnnserUserAvatarList"
          :key="index"
          >{{ item.title }}</a-select-option
        >
      </a-select>
      <!--<a-tree-select style="width: 300px" :treeData="AnnserUserAvatarList" :value="treeSelect" @change="_selectData" treeCheckable searchPlaceholder="请选择"/>-->
      <a-button
        type="primary"
        style="margin-left:20px;"
        @click="_showChildCutData()"
        >查询</a-button
      >
      <a-drawer
        :title="info.Title"
        width="1000"
        :closable="false"
        @close="_childCutData"
        :visible="childCutData"
      >
        <div style="display: inline-block">
          <span style="margin-right: 10px;">题目</span>
          <a-select
            style="width: 150px"
            defaultValue=""
            @change="_getOptionTitle"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              :value="item.Id"
              v-for="(item, index) in titleList"
              >{{ item.OptionTitle }}</a-select-option
            >
          </a-select>
        </div>

        <div style="display: inline-block">
          <span style="margin-right: 10px;margin-left:30px;">日期</span>
          <a-range-picker style="width: 220px;" @change="_optionDate" />
        </div>
        <a-button
          type="primary"
          style="margin-left:20px;"
          @click="_searchDetailMsg"
          >查询</a-button
        >
        <div style="margin-top:20px;">
          <a-table
            :columns="optionColumns"
            :dataSource="optionData"
            size="middle"
            bordered
          ></a-table>
        </div>
      </a-drawer>
    </a-drawer>

    <a-drawer
      title="发送补考试卷"
      placement="right"
      width="530"
      :closable="false"
      @close="onCloseSendResit"
      :visible="showSendResitVisible"
    >
      <sapn
        >注意：系统会重新生成试卷，通过企业小助手发送给缺考、不及格的考生。</sapn
      >
      <div class="resit-row">
        <span>标题：</span
        ><a-input v-model="send_title" placeholder="发送标题"></a-input>
      </div>
      <div class="resit-row">
        <span>副标题：</span
        ><a-input v-model="send_subtitle" placeholder="发送副标题"></a-input>
      </div>

      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="onCloseSendResit"
          >取消</a-button
        >
        <a-button type="primary" @click.stop="SendResitOk">确定</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
import person from 'components/SelectPersonnel/SelectPersonnel' //选人
export default {
  name: 'Statistics',
  components: { person },
  data () {
    return {
      itemActive: 2,
      type: 1,
      Qid: '',
      columns: [
        {
          title: '考试名称',
          dataIndex: 'name',
          align: 'center',
          rowKey: 'name'
        },
        {
          title: '应考人数',
          dataIndex: 'number',
          align: 'center',
          rowKey: 'number'
        },
        {
          title: '实考人数',
          dataIndex: 'realcount',
          align: 'center',
          rowKey: 'number'
        },
        {
          title: '及格率',
          dataIndex: 'passrate',
          align: 'center',
          rowKey: 'passrate'
        },
        {
          title: '最高分',
          dataIndex: 'highest',
          align: 'center',
          rowKey: 'highest'
        },
        {
          title: '最低分',
          dataIndex: 'low',
          align: 'center',
          rowKey: 'low'
        },
        {
          title: '平均分',
          dataIndex: 'average',
          align: 'center',
          rowKey: 'average'
        },
        {
          title: '平均时长',
          dataIndex: 'averagetime',
          align: 'center',
          rowKey: 'averagetime'
        }
      ],
      optionColumns: [
        {
          title: '编号',
          dataIndex: 'sNumber',
          rowKey: 'sNumber',
          align: 'center',
          width: 60
        },
        {
          title: '问题',
          dataIndex: 'OptionTitle',
          align: 'center',
          rowKey: 'OptionTitle'
        },
        {
          title: '回答内容',
          dataIndex: 'Answer',
          align: 'center',
          rowKey: 'Answer'
        },
        {
          title: '关联对象',
          dataIndex: 'Relevance',
          align: 'center',
          rowKey: 'Relevance'
        },
        {
          title: '调查对象',
          dataIndex: 'Target',
          align: 'center',
          rowKey: 'Target',
          width: 120
        },
        {
          title: '添加人',
          dataIndex: 'Name',
          align: 'center',
          rowKey: 'Name',
          width: 120
        }
      ],
      optionData: [],
      tableData: [],
      chartData: [],
      info: [],
      classifyList: [],
      optionList: [],
      startDate: null,
      endDate: null,
      jumpPage: 1,
      currPage: 1,
      num: 0,
      screenStatus: '',
      moreTableData: '',
      moreData: '',
      visible: false,
      cutData: false,
      childCutData: false,
      moreColumns: [
        {
          title: '回答内容',
          align: 'center',
          key: 'Answer',
          dataIndex: 'Answer'
        },
        {
          title: '备注',
          align: 'center',
          key: 'Remark',
          dataIndex: 'Remark'
        },
        {
          title: '关联对象',
          dataIndex: 'RelevanceName',
          key: 'RelevanceName',
          align: 'center'
        },
        {
          title: '调查对象',
          dataIndex: 'UserType',
          key: 'UserType',
          align: 'center',
          width: 100
        },
        {
          title: '添加人',
          dataIndex: 'UserName',
          key: 'UserName',
          align: 'center',
          width: 100
        }
      ],
      releanceId: '',
      searchTableInfo: '',
      examineeColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          align: 'center',
          width: 50
        },
        {
          title: '姓名',
          dataIndex: 'UserName',
          align: 'center',
          width: 150
        },
        {
          title: '工号',
          dataIndex: 'Num',
          align: 'center',
          width: 150
        },
        {
          title: '所属中心',
          dataIndex: 'SchooleName',
          align: 'center',
          width: 150
        },
        {
          title: '部门',
          dataIndex: 'SectionName',
          align: 'center',
          width: 150
        },
        {
          title: '答题时长',
          dataIndex: 'Duration',
          align: 'center',
          width: 150
        },
        {
          title: '交卷时间',
          dataIndex: 'SubmiTime',
          align: 'center',
          width: 150
        },
        {
          title: '成绩',
          dataIndex: 'UserScore',
          align: 'center',
          width: 150
        },
        {
          title: '结果',
          dataIndex: 'Result',
          align: 'center',
          width: 150
        }
      ],
      examineeData: [],
      AnnserUserAvatarList: [],
      treeSelect: '',
      treeData: [],
      titleList: [],
      optionTitleId: '',
      OptionStartDate: '',
      OptionEndDate: '',
      searchId: '',
      moreTotal: 0,
      tableMoreId: '',
      tableMorePage: 1,
      WJPage: 0,
      relevanceText: '',
      personObj: {
        role: 0,
        positions: 0,
        rank: 0,
        nums: 10000, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人 不要常用联系人传空''
        selectArr: this.relevanceObject //要传入的数据
      },
      relevanceObject: '',
      showSendResitVisible: false,
      send_title: '',
      send_subtitle: ''
    }
  },
  created () {
    this.Qid = this.$route.query.id
    this.type = this.$route.query.type ? this.$route.query.type : 1
    if (this.type == 2) {
      this._searchTableInfo(1)
    }
    this.$axios.get(1206, { label: 'Questionnaire' }, res => {
      if (res.data.code == 1) {
        this.classifyList = res.data.data
      }
    })
    this._treeInfoData()
  },
  mounted () {
    this.Qid = this.$route.query.id
    if (this.type == 1) {
      this._map()
    } else {
      let self = this
      this.$message.loading('加载中...', 0)
      this.$axios.get(5836113, { Qid: this.Qid }, res => {
        if (res.data.code == 1) {
          this.tableData = res.data.data.Stat
          this.chartData = res.data.data.chart
          this.info = res.data.data.info
          this.AnnserUserAvatarList = res.data.data.AnnserUserAvatarList
        } else {
          this.$message.error(res.data.msg)
        }
        this.$message.destroy()
        let echarts = require('echarts')
        let myChart = echarts.init(document.getElementById('category'))
        let option = {
          xAxis: {
            type: 'category',
            data: self.chartData.Y.name,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          series: [
            {
              data: self.chartData.Y.value,
              type: 'bar',
              barWidth: '20',
              itemStyle: {
                normal: {
                  color: '#7672F9'
                }
              }
            }
          ]
        }
        myChart.setOption(option, true)
      })
    }
  },
  methods: {
    _PageChange (pageNumber) {
      this._searchTableInfo(pageNumber)
    },
    _optionDate (value) {
      let date1 = new Date(this.OptionStartDate)
      this.OptionStartDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.OptionEndDate)
      this.OptionEndDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    },
    _getOptionTitle (value) {
      this.optionTitleId = value
    },
    _searchDetailMsg () {
      let data = {
        Qid: this.$route.query.id,
        OptionId: this.optionTitleId,
        UserId: this.searchId,
        startDate: this.OptionStartDate,
        endDate: this.OptionEndDate
      }
      let msg = this.$message.loading('搜索中...', 0)
      this.$axios.get(5836118, data, res => {
        if (res.data.code == 1) {
          this.optionData = res.data.data
        } else {
          this.optionData = []
        }
        this.$message.destroy(msg)
      })
    },
    _showChildCutData () {
      this._searchDetailMsg()
      this.childCutData = true
    },
    _treeInfoData () {
      let data = {
        Qid: this.$route.query.id,
        sValue: '',
        sectionId: '',
        startTime: '',
        endTime: '',
        Page: ''
      }
      this.$axios.get(5836116, data, res => {
        if (res.data.code == 1) {
          this.classifyList = res.data.data
        }
      })
    },
    _selectData (value) {
      let self = this
      let listId = ''
      for (let i = 0; i < value.length; i++) {
        for (let j = 0; j < self.AnnserUserAvatarList.length; j++) {
          if (value[i] == self.AnnserUserAvatarList[j].title) {
            listId += self.AnnserUserAvatarList[j].value + ','
          }
        }
      }
      listId = listId.split(',')
      listId.splice(listId.length - 1)
      this.searchId = listId.join(',')
    },
    _showCutData () {
      if (this.titleList.length == 0) {
        this.$axios.get(5836117, { Qid: this.$route.query.id }, res => {
          if (res.data.code == 1) {
            this.titleList = res.data.data
          }
        })
      }
      this.cutData = true
    },
    _personageData (id) {
      if (this.titleList.length == 0) {
        this.$axios.get(5836117, { Qid: this.$route.query.id }, res => {
          if (res.data.code == 1) {
            this.titleList = res.data.data
          }
        })
      }
      this.searchId = id
      this._searchDetailMsg()
      this.cutData = true
      this.childCutData = true
    },
    _childCutData () {
      this.childCutData = false
    },
    _cutData () {
      this.cutData = false
    },
    _releanceObject (value) {
      this.releanceId = value
    },
    onClose () {
      this.visible = false
    },
    _searchTableInfo (page) {
      this.$message.loading('查询中...', 0)
      let data = {
        Qid: this.$route.query.id,
        sValue: this.screenStatus,
        sectionId: '',
        startTime: this.startDate,
        endTime: this.endDate,
        Page: page,
        OrgData: JSON.stringify(this.relevanceObject)
      }
      this.$axios.get(5836116, data, res => {
        if (res.data.code == 1) {
          this.examineeData = res.data.data
          this.WJPage = res.data.count
          this.$message.destroy()
        } else {
          this.$message.error(res.data.msg)
          this.$message.destroy()
        }
      })
    },
    _screenStatus (value) {
      this.screenStatus = value
    },
    _deriveData () {
      this.$message.loading('文件下载中...', 0)
      //数据导出
      this.$axios.get(
        5836115,
        {
          Qid: this.$route.query.id,
          Type: this.$route.query.type,
          UserId: user.uid
        },
        res => {
          if (res.data.code == 1) {
            window.open(res.data.url)
            this.$message.destroy()
          } else {
            this.$message.error(res.data.msg)
            this.$message.destroy()
          }
        }
      )
    },
    _moreTable (page) {
      this._getTableMoreData(this.tableMoreId, page)
    },
    _more (id) {
      this.tableMoreId = id
      this._getTableMoreData(id, 1)
    },
    _getTableMoreData (id, page) {
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        5836114,
        {
          Qid: this.$route.query.id,
          OptionId: id,
          Page: page,
          releanceId: this.releanceId,
          startDate: this.startDate,
          endDate: this.endDate
        },
        res => {
          if (res.data.code == 1) {
            this.visible = true
            this.moreData = res.data.data
            this.moreTableData = res.data.data.list
            this.moreTotal = res.data.data.Count
          } else {
            this.$message.error(res.data.msg, 0)
          }
          this.$message.destroy()
        }
      )
    },
    _issueProject (value) {
      //发布项目
      let data = {
        UserId: user.uid,
        Id: this.$route.query.id,
        changeType: 'change',
        Status: this.info.Enable == 1 ? 0 : 1
      }
      let self = this
      this.$axios.post(5836110, data, res => {
        if (res.data.code == 1) {
          this.info.Enable = this.info.Enable == 1 ? 0 : 1
          self.$message.success(res.data.msg)
        } else {
          self.$message.error(res.data.msg)
        }
      })
    },
    _returnPage () {
      window.history.back(-1)
    },
    _map () {
      this.$message.loading('加载中...', 0)
      this.Qid = this.$route.query.id
      this.$axios.get(5836113, { Qid: this.Qid }, res => {
        if (res.data.code == 1) {
          this.optionList = res.data.data.OptionList
          this.info = res.data.data.info
          this.AnnserUserAvatarList = res.data.data.AnnserUserAvatarList
          this.$message.destroy()
        } else {
          this.$message.error(res.data.msg)
        }
        let echarts = require('echarts')
        let list = this.optionList
        var t1 = window.setTimeout(function () {
          for (let i = 0; i < list.length; i++) {
            let documentId = document.getElementById('main' + i)
            if (documentId) {
              let myChart = echarts.init(documentId)
              let option = {
                tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                  {
                    name: '占比',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: list[i].Chart.data,
                    itemStyle: {
                      emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      },
                      normal: {
                        label: {
                          show: true,
                          formatter: '{b} : {c} ({d}%)'
                        },
                        labelLine: {
                          show: true
                        }
                      }
                    }
                  }
                ]
              }
              myChart.setOption(option, true)
            }
          }
        }, 300)
      })
    },
    _skipPage (i) {
      if (i == 1) {
        this.$router.push({
          path: 'issueQuestionnaire',
          query: { type: this.$route.query.type, id: this.$route.query.id }
        })
      } else if (i == 0) {
        this.$router.push({
          path: 'addQuestionnaire',
          query: { type: this.$route.query.type }
        })
      }
    },
    _createdDate (value) {
      if (value.length == 0) {
        this.startDate = ''
        this.endDate = ''
        return false
      }
      this.startEndDate = value
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    },
    _searchData () {
      // if(!this.startDate || !this.endDate){
      //     this.$message.error('请选择查询时间');
      //     return
      // }
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        5836113,
        {
          Qid: this.Qid,
          startDate: this.startDate,
          endDate: this.endDate,
          relevance_id: this.releanceId
        },
        res => {
          if (res.data.code == 1) {
            this.optionList = res.data.data.OptionList
            this.info = res.data.data.info
            let echarts = require('echarts')
            let list = this.optionList
            var t1 = window.setTimeout(function () {
              for (let i = 0; i < list.length; i++) {
                let documentId = document.getElementById('main' + i)
                if (documentId) {
                  let myChart = echarts.init(documentId)
                  let option = {
                    tooltip: {
                      trigger: 'item',
                      formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series: [
                      {
                        name: '访问来源',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '50%'],
                        data: list[i].Chart.data,
                        itemStyle: {
                          emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                          }
                        }
                      }
                    ]
                  }
                  myChart.setOption(option, true)
                }
              }
            }, 1000)
          } else {
            this.$message.error(res.data.msg)
          }
          this.$message.destroy()
        }
      )
    },
    jumpGet (optionid, i) {
      this.$axios.get(
        5836114,
        {
          Qid: this.Qid,
          OptionId: optionid,
          startDate: this.startDate,
          endDate: this.endDate,
          Page: this.jumpPage
        },
        res => {
          this.currPage = this.jumpPage
          if (res.data.code == 1) {
            this.optionList[i].Chart.OptionId = optionid
            this.optionList[i].Chart.data = res.data.data.list
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _relevanceObject (list) {
      let self = this
      self.relevanceText = ''
      this.relevanceObject = list
      for (let i = 0; i < list.length; i++) {
        if (self.relevanceText != '') {
          self.relevanceText += ','
        }
        self.relevanceText += list[i].title
      }
    },
    _reset () {
      this.relevanceText = ''
      this.relevanceObject = ''
      this.screenStatus = ''
      this.startDate = ''
      this.endDate = ''
    },
    sendResitModel () {
      this.showSendResitVisible = true
    },
    onCloseSendResit () {
      this.send_title = ''
      this.send_subtitle = ''
      this.showSendResitVisible = false
    },
    SendResitOk () {
      let self = this
      if (!this.send_title) {
        this.$message.error('标题不能为空')
        return
      }
      if (!this.send_subtitle) {
        this.$message.error('副标题不能为空')
        return
      }
      const hide = this.$message.loading('请求中...', 0)
      setTimeout(hide, 1000)
      this.$axios.post(
        7000013,
        {
          Qid: this.Qid,
          title: this.send_title,
          subtitle: this.send_subtitle
        },
        res => {
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.showSendResitVisible = false
          // this.$message.destroy();
        }
      )
    }
  }
}
</script>

<style lang="less">
.Statistics {
  height: 100%;
  .crumbs {
    position: relative;
    height: 64px;
    padding-right: 15px;
    line-height: 64px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    color: #666;
    font-size: 14px;
    .return {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #9d9d9d;
      cursor: pointer;
    }
    .crumbs-icon {
      margin-left: 30px;
      margin-right: 30px;
      font-size: 14px;
      color: #9d9d9d;
    }
    .crumbs-item {
      position: relative;
      cursor: pointer;
      span {
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 100%;
        height: 2px;
      }
    }
  }
  .questionnaire-title {
    margin: 20px 0;
    padding: 0 10px;
    .questionnaire-text {
      color: #444;
      font-size: 14px;
    }
    .questionnaire-update {
      color: #999;
      font-size: 14px;
    }
  }
  .container {
    margin-top: 20px;
    align-items: end;
    flex: 1;
    .content-left {
      width: 315px;
      padding: 30px 15px;
      background: #fff;
      border-radius: 4px;
      min-height: calc(~'100% - 20px');
      .item {
        margin-bottom: 20px;
        span {
          margin-right: 20px;
          color: #666;
          font-size: 14px;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        background: #f0f0f0;
      }
    }
    .content-right {
      flex: 1;
      .search-header {
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        .search-text {
          margin-right: 15px;
          color: #666;
          font-size: 14px;
        }
        .sub-user {
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-left: -5px;
            cursor: pointer;
          }
          .more-user {
            margin-left: 4px;
            color: #666;
            font-size: 16px;
            vertical-align: middle;
            cursor: pointer;
          }
        }

        .person {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1000;
        }
      }
      .content-list {
        margin-top: 15px;
        background: #fff;
        .list-item {
          align-items: end;
          .title {
            min-height: 280px;
            flex: 1;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            .title-text {
              color: #333;
              font-size: 18px;
            }
            .item-row {
              margin-top: 20px;
              font-size: 14px;
              color: #333;
            }
          }
          .data {
            position: relative;
            width: 450px;
            min-height: 250px;
            .sub-num {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              font-size: 14px;
              color: #999;
              padding: 0 15px;
            }
            .data-list {
              width: 90%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border: 0.5px solid #ddd;
              border-bottom: none;
              .list-row {
                padding: 6px 12px;
                border-bottom: 0.5px solid #ddd;
                .row-label {
                  color: #666;
                  font-size: 14px;
                  overflow: hidden; //超出的文本隐藏
                  text-overflow: ellipsis; //溢出用省略号显示
                  white-space: nowrap;
                }
                .more {
                  color: #666;
                  font-size: 14px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .right-top {
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        .header {
          padding-bottom: 15px;
          padding-left: 10px;
          font-size: 16px;
          color: #666;
          border-bottom: 1px solid #f2f2f2;
        }
        .content {
          padding: 15px;
          .row {
            display: inline-block;
            width: 48%;
            margin-bottom: 15px;
            font-size: 15px;
            color: #666;
          }
        }
      }
      .container-content {
        margin-top: 20px;
        padding: 15px;
        background: #fff;
        border-radius: 4px;
      }
    }
  }
}
.MoreData {
  height: 100%;
  background: #fff;
  .issue-list {
    margin-bottom: 30px;
    > li {
      padding: 7px 10px;
      border: 1px solid #ddd;
      border-bottom: none;
    }
    li:last-child {
      border-bottom: 1px solid #ddd;
    }
  }
}

.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}

.resit-row {
  width: 400px;
  margin-top: 10px;
}
</style>
